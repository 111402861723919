import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

function Loading() {
    return (
        <Timeline position="alternate">
        <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
            <Skeleton animation="wave" sx={{marginRight:'auto'}} width={50} variant="text" />
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
            </TimelineDot>
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
            <span><Skeleton animation="wave" width={65} variant="text" component={'h1'} /></span>
            <Skeleton animation="wave" width={100} variant="text"  component={'h3'} />
            </TimelineContent>
        </TimelineItem>

        <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                <Skeleton animation="wave" width={50} variant="text" />
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot/>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2,textAlign:'right' }}>
                <Skeleton sx={{marginRight: "auto" }} animation="wave" width={65} variant="text" component={'h1'} />
                <Skeleton sx={{marginRight: "auto"  }} animation="wave" width={100} variant="text"  component={'h3'} />
            </TimelineContent>
        </TimelineItem>

        <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
            <Skeleton animation="wave" sx={{marginRight:'auto'}} width={50} variant="text" />
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
            </TimelineDot>
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
            <span><Skeleton animation="wave" width={65} variant="text"  component={'h1'} /></span>
            <Skeleton animation="wave" width={100} variant="text"  component={'h3'} />
            </TimelineContent>
        </TimelineItem>

        </Timeline>
    )
}

export default Loading


