import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Logo from './Logo';
import SearshBox from './SearshBox';

const Navbar = ({onChange,value,name}) => {
  return (
      <AppBar id="navbar" position="static" sx={{ position: 'relative',zIndex:'5' }}>
        <Toolbar  sx={{flexDirection: 'row',justifyContent: 'space-between'}}>
          <Box size="large"
          edge="start"
          aria-label="open drawer"
          sx={{ mr: 2 ,color: 'common.white',height: '38px',margin: '1rem' }}>
            <Logo height="100%" />
          </Box>
          <Box sx={{
            maxWidth:'300px',
            width:'100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            margin: 'auto',
          }}>
            <SearshBox onChange={onChange} value={value} name={name} />
          </Box>
        </Toolbar>
      </AppBar>
  );
};

export default Navbar;