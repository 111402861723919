import './App.css';
import Navbar from './components/Navbar';
import Header from './components/Header';
import { createTheme,ThemeProvider } from '@mui/material/styles';
import {useMutation, gql} from "@apollo/client";
import { useState } from 'react';
import Home from './components/Home';
import "dayjs/locale/ar-dz";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.locale("ar-dz");
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

const GET_TRACE_BOX = gql`
    mutation trackBox($codeBox: ID) {
        trackBox(codeBox: $codeBox) {
            id,
            code_box
            recipient_name
            recipient_phone1
            recipient_city
            recipient_address
            
            traceBox {
                id
                status
                note
                createdAt
                person {
                    first_name
                    last_name
                    phone01
                }
                stock {
                    name
                }
            }
        }
    }
`;

const theme = createTheme({
    palette: {
        primary: {
            main: '#7d749e',
        },
        secondary: {
            light: '#0066ff',
            main: '#0044ff',
            contrastText: '#ffcc00',
        }
    },
    typography: {
        fontFamily: ['Almarai', 'sans-serif'].join(','),
    }
});

function getItem(code_box) {
    let trackBox = JSON.parse(localStorage.getItem('trackBox'));
    if (trackBox == null) { return null }
    else{
        const index = trackBox.findIndex(box => box.code_box === code_box)
        if (index!==-1) return trackBox[index];
        return null
    }
}

function setItem(box) {
    let trackBox = JSON.parse(localStorage.getItem('trackBox'));
    if (trackBox == null){
        trackBox = [box];
        localStorage.setItem('trackBox', JSON.stringify(trackBox));
        return
    } else{
        const index = trackBox.findIndex(item => item.code_box === box.code_box)
        if (index!==-1) {
            trackBox[index]= box
            localStorage.setItem('trackBox', JSON.stringify(trackBox));
            return
        }
        trackBox=[...trackBox,box]
        localStorage.setItem('trackBox', JSON.stringify(trackBox));
        return
    }
}

function App() {
    const [searshInput, searshInputSet] = useState('');
    const [submit, submitSet] = useState(false);
    const [state, setstate] = useState(null);
    const [searchBox, { loading, error }] = useMutation(GET_TRACE_BOX, {
        onCompleted: ({trackBox}) => {
            if (trackBox) {
                setstate(trackBox);
                setItem(trackBox)
            }
        },
        errorPolicy: 'all',
        onError: (error) => {
            console.error(error.message);
        }
    });

    function handleChange(e) {
        searshInputSet(e.target.value)
    }

    function handleSubmit(e) {
        e.preventDefault()
        submitSet(true)
        setstate(null)

        setTimeout(()=>{
            setstate(getItem(searshInput))
        }, 1000)

        setTimeout(()=>{
            searchBox({variables: {"codeBox": searshInput}});
        }, 2000)

        if (typeof error === 'undefined' || state === null || submit === false) {
            document.getElementById('Header').style.transition='.6s all';
            document.getElementById('Header').style.transform = "translateY(-102vh)";
            setTimeout(()=>{
                document.getElementById('Header').style.display = "none";
            },1000);
        }
    }

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <form onSubmit={handleSubmit}>
                    {submit ?
                        <>
                          <Navbar onChange={handleChange} value={searshInput} name="searshInput" />
                          <Home data={state} loading={loading} error={error} />
                        </>
                        : ''
                    }
                    <Header onChange={handleChange} value={searshInput} name="searshInput" searshInputSet={searshInputSet} />
                </form>
            </ThemeProvider>
        </div>
    );
}

export default App;