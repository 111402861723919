import React from "react";
import { styled } from "@mui/system";
import { default as MuiTimeline } from "@mui/lab/Timeline";
import { grey} from "@mui/material/colors";

const StyledTimeLine = styled(MuiTimeline)`
    padding: 0;
    margin: 0;
    width: 100%;
  
    & .MuiTimelineItem-root {
        &:before {
            padding: 0;
            flex: 0;
        }
        & .MuiTimelineContent-root {
            padding: 18px 16px;
        }
        & .MuiTimelineSeparator-root {
            & .MuiTimelineDot-root {
                width: 20px;
                height: 20px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                & .innerCircle {
                    width: 10px;
                    height: 10px;
                    background-color: #fff;
                    border-radius: 50%;
                }
            }
    
            & .MuiTimelineConnector-root {
                background-color: ${grey[200]};
              
            }
        }
        & .Timeline-card {
            //box-sizing: "border-box";
            //width: "auto";
            height: 128px;
            padding: 14px 18px;
            background-color: #fff;
            border-radius: 4px;
        }
    }
`;


export const Timeline = (props) => {
  return (
    <StyledTimeLine {...props}>
      {props.children}
    </StyledTimeLine>
  );
};

export default Timeline;
