import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}` ),
      );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const httpLink = new HttpLink({
  uri: 'https://api.qafilaty.com/graphql',
  credentials: 'include' 
})
const client = new ApolloClient({
  link: from([
      errorLink,
      httpLink
  ]),
  cache: new InMemoryCache(),
  credentials: 'include'
});


ReactDOM.render(
      <ApolloProvider client={client} >
      <React.StrictMode>
              <App />
      </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
