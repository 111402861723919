import { Chip as MuiChip, chipClasses } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { lighten } from "@mui/material/styles";

const StyledChip = styled(MuiChip)(({theme, color, customColor, size, rounded, doted,}) => {
    let selectedColor = customColor ?? theme?.palette[color]?.main ?? theme.palette["primary"].main;
    let lighterShade = lighten(selectedColor, 0.9);
    return {
        borderRadius: rounded === "true" ? "" : "2px",
        backgroundColor: lighterShade,
        color: selectedColor,
        // small chip
        ...(size === "small" && {
            [`&.${chipClasses.sizeSmall}`]: {
                height: "20px",
                [`& .${chipClasses.labelSmall}`]: {
                    padding: "4px 8px",
                    display: "inline-flex",
                    alignItems: "center",
                    columnGap: doted ? 4 : 0,
                    color: selectedColor,
                    ...(doted && {
                        [`& .dot`]: {
                            backgroundColor: selectedColor,
                            display: "inline-block",
                            width: 5,
                            height: 5,
                            borderRadius: "50%",
                        },
                    }),
                },
            },
        }),

        // medium chip
        ...(size === "medium" && {
            [`&.${chipClasses.sizeMedium}`]: {
                height: "25px",

                [`& .${chipClasses.labelMedium}`]: {
                    padding: "6px 12px",
                    display: "inline-flex",
                    alignItems: "center",
                    columnGap: doted ? 6 : 0,
                    color: selectedColor,
                    ...(doted && {
                        [`& .dot`]: {
                            backgroundColor: selectedColor,
                            display: "inline-block",
                            width: 7,
                            height: 7,
                            borderRadius: "50%",
                        },
                    }),
                },
            },
        }),
    };
});

const Chip = (props) => {
    return (
        <>
            <StyledChip {...props}
                label={
                    <>
                        <span className="dot"></span>
                        <span className="label">{props.label}</span>
                    </>
                }
            />
        </>
    );
};

export default Chip;
