import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import img from '../assets/empty_data.svg'
function Empty({value}) {
    return (
        <Box >
            <img src={img} alt="" srcSet="" />
            <Typography sx={{fontWeight:'400', color:'#455a64'}} variant="h6" gutterBottom component="div">
            {value} 
            </Typography>
            <Typography sx={{fontWeight:'400', color:'#455a64'}} variant="h6" gutterBottom component="div">
                There is no Box has this tracking code. 
            </Typography>
            <Button type="submit" variant="outlined">Try again</Button>
        </Box>
    )
}

export default Empty