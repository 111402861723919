import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';

function FailedToFetch() {
    return (
        <Box>
            <SignalWifiConnectedNoInternet4Icon sx={{fontSize:'5rem',color:'#aaa'}} />
            <Typography sx={{fontSize:'1rem',color:'#555'}}>No Internt Accesse</Typography>
            <Button type="submit" variant="outlined" sx={{m:'1rem .5rem'}}>Try again</Button>
        </Box>
    )
}

export default FailedToFetch
