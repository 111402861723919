import React,{useState} from 'react';
import { Divider, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const Navbar = ({onChange,value,name}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  return (
          <Paper
      component="div"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
    >
            <IconButton type="submit"  sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
      required
        onChange={onChange} value={value} name={name}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Qaf-xxxxxx"
        inputProps={{ 'aria-label': 'Search Trace Boxes' }}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="ادخل كود الطرد"
              >
                <IconButton onClick={handleTooltipOpen} color="primary" sx={{ p: '10px' }} aria-label="directions">
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
    </Paper>
  );
};

export default Navbar;