import React from 'react'
import Loading from "./Loading";
import Empty from './Empty';
import CustomizedTimeline from "./CustomizedTimeline";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import FailedToFetch from './FailedToFetch';

const MuiBox = styled(Box)({
    minHeight:'85vh',
    display:'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
});

function Home({ data, loading, error }) {
    if (error && data==null)
        return (error.message==='Failed to fetch')
            ?<MuiBox><FailedToFetch/></MuiBox>
            :<MuiBox><Empty/></MuiBox> ;

    const TimeLine = ()=>{
        try {
            return <CustomizedTimeline data={data} />
        } catch (error) {
            return ''
        }
    }

    return (
        <MuiBox>
            {((data === null && loading) || data===null)
                ? <Loading />
                : <TimeLine />
            }
        </MuiBox>
    )
}

export default Home
