import React, { useEffect, useRef } from 'react'
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';

const getLocalBoxes = ()=>{
    let trackBox = JSON.parse(localStorage.getItem('trackBox'));
    if (trackBox == null) {
      return null
    }
    else{
      return trackBox
    }
}
function deleteLocalBox(code_box){
    let trackBox = JSON.parse(localStorage.getItem('trackBox'));
    if (trackBox == null) {
      return 
    }
    else{
      trackBox = trackBox.filter(box=>box.code_box!==code_box);
      localStorage.setItem('trackBox',JSON.stringify(trackBox));
    }
}

function Chips({onChange,value,name,searshInputSet}) {
    const [chipData, setChipData] = React.useState(getLocalBoxes());
    const ref = useRef()
    useEffect(() => {
    }, [chipData])
    const handleDelete = (code_box) => () => {
    deleteLocalBox(code_box)
    setChipData(prev=>prev.filter(box=>box.code_box!==code_box))
    };
    const handleChipClick = (code_box) => () => {
          searshInputSet(code_box)
          setTimeout(() => {
            ref.current.click()
          }, 100);
    };

    if(chipData===[]||chipData==null||chipData.length===0) return ''

    return (
        <Box sx={{border:'0.5px solid #eeeeee2e',display:'flex',flexWrap: 'wrap',borderRadius:'3px',margin:'1rem 0',width:'95%',padding:'1rem' }}>
            {chipData.map((data,index) =>(
                <Box key={`${index}-Box`}>
                    <Chip
                        key={index} clickable
                        onClick={handleChipClick(data.code_box)}
                        onDelete={handleDelete(data.code_box)}
                        label={data.code_box}
                        sx={{margin: '0.1rem', paddingLeft: "10px", color:'#f5f5f5', ".MuiChip-deleteIcon":{fill:'#eeeeee8a', marginRight: "-5px"}}}
                        size="medium" variant="outlined"
                    />
                </Box>
            ))}
            <button ref={ref} style={{display:'none'}} type="submit"></button>
        </Box>
    )
}

export default Chips
