const normal_cycle = {
  1: {
    nameAr: "طلب جديد",
    color: "#7e57c2",
  },
  2: {
    nameAr: "طلب جديد pickup",
    color: "#7d749e",
  },
  3: {
    nameAr: "يتم جلبها للفرع",
    color: "#42a5f5",
  },
  4: {
    nameAr: "في الفرع للتوصيل",
    color: "#616161",
  },
  5: {
    nameAr: "قيد التوصيل",
    color: "#2196f3",
  },
  6: {
    nameAr: "في الفرع للاستلام",
    color: "#b2ff59",
  },
  7: {
    nameAr: "قيد التوصيل للاستلام",
    color: "#42a5f5",
  },
  8: {
    nameAr: "تم التوصيل",
    color: "#00e676",
  },
  9: {
    nameAr: "تمت محاسبة السائق",
    color: "#bdbdbd",
  },
};

const commercial_cycle = {
  10: {
    nameAr: "المبلغ قيد التوصيل",
    color: "#42a5f5",
  },
  11: {
    nameAr: "المبلغ في الفرع",
    color: "#bdbdbd",
  },
  12: {
    nameAr: "المبلغ جاهز للاستلام",
    color: "#b2ff59",
  },
  13: {
    nameAr: "تم استلام المبلغ",
    color: "#00e676",
  },
};

const retrieve_cycle = {
  14: {
    nameAr: "طلب ارجاع",
    color: "#ff9100",
  },
  15: {
    nameAr: "في الفرع للارجاع",
    color: "#ff9100",
  },
  16: {
    nameAr: "قيد الارجاع للفرع",
    color: "#ff9100",
  },
  17: {
    nameAr: "يتم الارجاع للعميل",
    color: "#ff9100",
  },
  18: {
    nameAr: "تم الارجاع",
    color: "#ff9100",
  },
};

const exchange_cycle = {
  19: {
    nameAr: "طلب استبدال",
    color: "#fbc02d",
  },
  20: {
    nameAr: "في الفرع للاستبدال",
    color: "#fbc02d",
  },
  21: {
    nameAr: "قيد الاستبدال للفرع",
    color: "#fbc02d",
  },
  22: {
    nameAr: "يتم الاستبدال للمستلم",
    color: "#fbc02d",
  },
  23: {
    nameAr: "تم الاستبدال",
    color: "#fbc02d",
  },
};

const abort_cycle = {
  24: {
    nameAr: "طلب الغاء",
    color: "#ff3d00",
  },
  25: {
    nameAr: "تم الالغاء",
    color: "#ff3d00",
  },
};

const postpone_cycle = {
  26: {
    nameAr: "طلب تأجيل",
    color: "#f9a825",
  },
  27: {
    nameAr: "تم التأجيل",
    color: "#f9a825",
  },
};

const traking_status = {
  0: {
    nameAr: "مسودة",
    color: "#5f9ea0",
  },
  ...normal_cycle,
  ...commercial_cycle,
  ...retrieve_cycle,
  ...exchange_cycle,
  ...abort_cycle,
  ...postpone_cycle,
  28: {
    nameAr: "فشل التوصيل",
    color: "#f44336",
  },
  29: {
    nameAr: "في الفرع فشل توصيلها",
    color: "#e53935",
  },
  30: {
    nameAr: "قيد التوصيل 2",
    color: "#2196f3",
  },
  31: {
    nameAr: "فشل التوصيل 2",
    color: "#f44336",
  },
  32: {
    nameAr: "في الفرع فشل توصيلها 2",
    color: "#e53935",
  },
  33: {
    nameAr: "قيد التوصيل 3",
    color: "#2196f3",
  },
  34: {
    nameAr: "فشل التوصيل 3",
    color: "#f44336",
  }
};

export default traking_status;

export {
  normal_cycle,
  commercial_cycle,
  retrieve_cycle,
  exchange_cycle,
  abort_cycle,
  postpone_cycle,
};