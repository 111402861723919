import * as React from 'react';
import {TimelineItem} from '@mui/lab';
import { grey} from "@mui/material/colors";
import dayjs from "dayjs";
import { Stack} from "@mui/material";

import Timeline from './Timeline/Timeline';
import TimelineSeparator from './Timeline/TimelineSeperator';
import TimelineContent from './Timeline/TimelineContent';
import Tracking_status from "../api/tracking_status"

export default function CustomizedTimeline({data}) {
    return (
        <Stack width={"100%"} height={"calc(100vh - 102px)"} padding={"1rem"} display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
            <Stack marginTop={"2.5rem"} padding={"1rem"} display={"flex"}  justifyContent={"center"} alignItems={"center"}
                   width={{xs: "90%", sm: "80%", md: "70%", lg: "50%"}}
            >
                <Timeline >
                    {data.traceBox.map((item, index) => (
                        item.status !== 9 ?
                            <TimelineItem key={index}>
                                <TimelineSeparator customColor={grey[500]}></TimelineSeparator>
                                <TimelineContent
                                    customColor={Tracking_status[item?.status || 0]?.color}
                                    name={`${item?.person?.first_name} ${item.person?.last_name}`}
                                    status={Tracking_status[item?.status || 0]?.nameAr}
                                    reside={item?.stock?.name}
                                    time={dayjs(item?.createdAt, "DD/MM/YYYY HH:mm:ss").fromNow()}
                                    note={item?.note}
                                    phone={item?.person?.phone01}
                                />
                            </TimelineItem>
                            : null
                    ))}
                </Timeline>
            </Stack>
        </Stack>
    );
}