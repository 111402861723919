import {TimelineConnector, TimelineDot, TimelineSeparator as MuiTimelineSeparator} from "@mui/lab";
import { Box } from "@mui/material";
import React from "react";

const TimelineSeparator = (props) => {
    return (
        <MuiTimelineSeparator>
            <TimelineDot {...props} style={{ backgroundColor: props.customColor }}>
                <Box className="innerCircle"/>
            </TimelineDot>
            <TimelineConnector />
        </MuiTimelineSeparator>
    );
};

export default TimelineSeparator;
