import {TimelineContent as MUITimelineContent} from "@mui/lab";
import { Box, Divider, Stack, Typography } from "@mui/material";
import {green, grey} from "@mui/material/colors";
import React from "react";
import Chip from "../Chip2";
import { PhoneIcon } from '@heroicons/react/outline'
import { default as RAvatar } from "react-avatar";

const TimelineContent = (props) => {
    return (
        <MUITimelineContent>
            <Box className="Timeline-card" border={"1px solid #eee"}>
                <Stack className="Timeline-innercontent" height="100%">
                    <Stack className="card-header" direction={"row"} justifyContent="space-between">
                        <Chip size="medium" label={props.status} customColor={props.customColor} />
                        <Typography variant="body2" color={grey[600]} marginTop="2px">{props.time}</Typography>
                    </Stack>
                    <Typography variant="body1" className="card-comment" flex="1" color={grey[400]} display="flex" alignItems="center" marginLeft={"8px"}>{props.note}</Typography>
                    <Stack direction={"row"} className="card-footer" gap="10px" alignItems="center" marginLeft={"8px"}>
                        <Typography variant="body1" color={grey[600]}>{props.reside}</Typography>
                        <Divider orientation="vertical" style={{borderRadius: 4, borderRightWidth: 2, borderLeftWidth: 0}}/>
                        <Stack direction="row" gap="6px" alignItems={"center"}>
                            <RAvatar size="24px" name={props.name} round style={{ fontFamily: "Montserrat-Arabic" }} maxInitials={1}></RAvatar>

                            <Typography variant="body1" color={grey[500]}>{props.name}</Typography>
                        </Stack>
                        <a href={`tel:${props.phone}`}
                           style={{
                               backgroundColor: "#EDF7EE",
                               width: "28px",
                               height: "28px",
                               borderRadius: "50%",
                               display: "flex",
                               justifyContent: "center",
                               alignItems: "center",
                               color: green[500]
                           }}
                        >
                            <PhoneIcon color={green[500]} height={"1rem"} />
                        </a>
                    </Stack>
                </Stack>
            </Box>
        </MUITimelineContent>
    );
};

export default TimelineContent;
