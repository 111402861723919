import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from './Logo';
import SearshBox from './SearshBox';
import Chips from './Chips';


const Header = ({onChange, value, name, searshInputSet}) => {
    return (
        <AppBar id="Header" position="static" sx={{ position: 'absolute',zIndex:6, top: 0, left: 0 }}>
            <Toolbar  sx={{height: '100vh',minHeight: '550px',flexDirection:'column',justifyContent:'center'}}>
                <Box size="large" edge="start" aria-label="open drawer" sx={{ mr: 2 ,color: 'common.white',height:50,margin:'1rem auto' }}>
                    <Logo height="100%" />
                </Box>

                <Box sx={{maxWidth:'525px', width:'65%', display: 'flex', alignItems: 'center', flexDirection: 'column', margin:'' }}>
                    <Box sx={{margin:'1rem auto'}}>
                        <Typography variant="subtitle1" gutterBottom component="div">تتبع طردك في الوقت الحقيقي</Typography>
                    </Box>
                    <SearshBox onChange={onChange} value={value} name={name} />
                    <Chips value={value} name={name} onChange={onChange} searshInputSet={searshInputSet} />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;